import { commonCodeApiType, commonCodeType } from '@/domain/common/api/commonCodeApi';
import { callMapping } from '@/api/context/callFun';
import { Module } from 'vuex';
import { RootState } from '../../../store/index';

export interface CodeModule {
  commonCode: commonCodeApiType;
}

export const commonCode: Module<CodeModule, RootState> = {
  namespaced: true,
  state: () => ({
    commonCode: { group: [] as commonCodeType[] },
  }),
  mutations: {
    addCodes(state, payload) {
      state.commonCode = { ...state.commonCode, ...payload };
      console.log(state.commonCode);
    },
  },
  getters: {
    getCodes(state, getters, rootState) {
      // 다른 store state 추론 가능
      return state.commonCode;
    },
  },
  actions: {
    // callApiCode({ state, commit, rootState }) {
    //   callMapping.callGet.call('http://localhost:8084/commonCodeList', {}, data => {
    //     commit('addCode', data);
    //   });
    // },
  },
};
