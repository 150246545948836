
import { accountApi, AccountListResponse } from '@/domain/account/api//accountApi';
import { AccountListpopupDataType } from '@/domain/account/popup/AccountListPopup.vue';
import DefaultPopupView from '@/components/popup/DefaultPopupView.vue';
import { ObjectType } from '@/util/type';
import { defineComponent, ref } from 'vue';
interface changeCalendarType {
  month: number;
  year: number;
}
interface calenderType {
  customData: {
    title: string;
    class: string;
  };
  key: number;
  bar?: {
    style: {
      backgroundColor: '#c8e6c9';
    };
  };
  dates: Date;
}
export default defineComponent({
  name: 'AccountList',
  setup() {
    const calendarData = ref({} as changeCalendarType);
    const isOpen = ref({
      isAccountListOpen: false,
      isAccountWriteOpen: false,
    });
    const attributes = ref([
      {
        customData: {
          title: '',
          class: '',
        },
        key: 9999999,
        bar: {
          style: {
            backgroundColor: '#c8e6c9',
          },
        },
        dates: new Date(),
      } as calenderType,
    ] as calenderType[] | undefined);
    const calendarMappingMap = {
      '00001': '#2196F3',
      '00002': '#f44336',
      '00003': '#2196F3',
      '00004': '#f44336',
      '99999': 'black',
    } as any;
    const totalMoney = ref('0'); //전체
    const consumptionMoney = ref('0'); //전체소비
    const spendingMoney = ref('0'); //전체지출
    const accountListPopupData = ref({} as AccountListpopupDataType);
    const accountWritePopupData = ref({} as AccountListpopupDataType);
    const changeCallCalendar = (calendar: changeCalendarType) => {
      totalMoney.value = '0';
      consumptionMoney.value = '0';
      spendingMoney.value = '0';
      calendarData.value = calendar;
      const month = (calendar.month + '').length == 1 ? '0' + calendar.month : calendar.month;
      const searchDate = `${calendar.year}${month}`;
      accountApi.list({ searchDate: searchDate, dateListType: '00002' }, data => {
        if (!data) {
          return;
        }
        const totalData = {} as ObjectType<number>;
        data.forEach((date: AccountListResponse) => {
          const day = date.accountDate.substring(date.accountDate.length - 2);
          if (!totalData[day]) totalData[day] = 0;
          totalData[day] += date.money;
        });
        //map -> list 변환
        //합계 데이터 생성
        const totalDataList = Object.keys(totalData).map((key: string) => {
          return {
            accountTypeCode: '99999',
            money: Number(totalData[key]),
            accountDate: calendar.year + '-' + month + '-' + key,
          } as AccountListResponse;
        });
        data = [...data, ...totalDataList];
        let tempTotalMoney = 0;
        let tempConsumptionMoney = 0;
        let tempSpendingMoney = 0;
        attributes.value = data.map((date: AccountListResponse, index: number) => {
          const day = date.accountDate.split('-');
          //구분별 통합 처리.
          tempTotalMoney += date.accountTypeCode === '99999' ? date.money : 0;
          tempConsumptionMoney += ['00001', '00003'].indexOf(date.accountTypeCode) > -1 ? date.money : 0;
          tempSpendingMoney += ['00002', '00004'].indexOf(date.accountTypeCode) > -1 ? date.money : 0;
          return {
            key: index,
            customData: {
              class: calendarMappingMap[date.accountTypeCode] as string,
              title: Number(date.money).toLocaleString('ko-KR') + '원',
            },
            dates: new Date(calendar.year, calendar.month - 1, Number(day[2])),
          } as calenderType;
        });
        totalMoney.value = Number(tempTotalMoney).toLocaleString('ko-KR');
        consumptionMoney.value = Number(tempConsumptionMoney).toLocaleString('ko-KR');
        spendingMoney.value = Number(tempSpendingMoney).toLocaleString('ko-KR');
      });
    };
    const callback = () => {
      changeCallCalendar(calendarData.value);
      isOpen.value.isAccountWriteOpen = false;
    };
    const openPopupList = (day: string) => {
      accountListPopupData.value.yyyymmdd = day;
      isOpen.value.isAccountListOpen = true;
    };
    const openPopupWrite = () => {
      const calendar = calendarData.value;
      const year = String(calendar.year);
      const month = (calendar.month + '').length == 1 ? '0' + calendar.month : calendar.month;
      accountWritePopupData.value.yyyymmdd = year + month + '01';
      isOpen.value.isAccountWriteOpen = true;
    };
    return {
      calendarData,
      callback,
      changeCallCalendar,
      attributes,
      isOpen,
      accountListPopupData,
      openPopupList,
      openPopupWrite,
      totalMoney,
      consumptionMoney,
      spendingMoney,
      accountWritePopupData,
      masks: {
        weekdays: 'WWW',
      },
    };
  },
  components: { DefaultPopupView },
});
