import { callDelete, callGet, callPost, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export interface AccountGroupNicknameRequest {
  searchDate: string;
}

export interface AccountGroupNicknameResponse {
  nickname: string;
  accountTypeCode: string;
  money: number;
  userFk: string;
}

export interface AccountGroupAccountTypeRequest {
  searchDate: string;
}

export interface AccountGroupAccountTypeResponse {
  accountCount: number;
  accountName: string;
  accountTypeName: string;
}

export const accountStatsApi = {
  nickname: (data: object, callback: (data: AccountGroupNicknameResponse[] | null) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/accountStats/nickname`;
    callGet.call(addr, data, accountStatsRules(callback), errorFun);
  },
  accountType: (data: object, callback: (data: AccountGroupAccountTypeResponse[] | null) => void, errorFun?: (error: object) => void) => {
    const addr = '/class/{classKey}/accountStats/accountType';
    callGet.call(addr, data, accountStatsRules(callback), errorFun);
  },
};

export function accountStatsRules<T>(callback: (callback: T | null) => void) {
  return (data: Response<T>) => {
    console.log(data);
    if (data.code === '00000') {
      callback(data.body);
    } else {
      callback(null);
    }
  };
}
