import { callDelete, callGet, callPost, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export interface AccountRequest {
  payCodeData: string;
  accountCodeData: string;
  accountTypeCodeData: string;
  description: string;
  money: number;
}

export interface AccountResponse {
  [day: string]: boolean[];
}

export const accountApi = {
  one: (key: string, data: object, callback: (data: Response<AccountOneResponse>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/account/${key}`;
    callGet.call(addr, data, callback, errorFun);
  },
  list: (data: AccountListRequest, callback: (data: AccountListResponse[] | null) => void, errorFun?: (error: object) => void) => {
    const addr = '/class/{classKey}/account';
    callGet.call(addr, data, accountRules(callback), errorFun);
  },
  save: (data: AccountInsertRequest, callback: (data: boolean | null) => void, errorFun?: (error: object) => void) => {
    const addr = '/class/{classKey}/account';
    callPost.call(addr, data, accountRules(callback), errorFun);
  },
  update: (data: object, callback: (data: boolean | null) => void, errorFun?: (error: object) => void) => {
    const addr = '/class/{classKey}/account';
    callPut.call(addr, data, accountRules(callback), errorFun);
  },
  delete: (key: string, data: object, callback: (data: boolean | null) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/account/${key}`;
    callDelete.call(addr, data, accountRules(callback), errorFun);
  },
};

export type dateListType = '00001' | '00002';

export interface AccountListRequest {
  searchDate: string;
  dateListType: dateListType;
}

export interface AccountListResponse {
  money: number;
  accountDate: string;
  accountTypeCode: string;
  startDate: string;
  endDate: string;
  nickname: string;
  numPk: string;
  payCode: string;
  accountCode: string;
  email: string;
  payName: string;
  accountName: string;
  accountTypeName: string;
  description: string;
  title: string;
}

export interface AccountInsertRequest {
  money: number;
  accountCode: string;
  payCode: string;
  description: string;
  accountTypeCode: string;
  searchDate: string;
  title: string;
}

export interface AccountUpdateRequest {
  money: number;
  accountCode: string;
  payCode: string;
  description: string;
  accountTypeCode: string;
  searchDate: string;
  title: string;
  numPk: string;
}

export interface AccountOneResponse {
  money: number;
  numPk: string;
  userFk: string;
  nickname: string;
  accountCode: string;
  payCode: string;
  accountName: string;
  payName: string;
  description: string;
  accountTypeCode: string;
  accountGroup: string;
  title: string;
}

export function accountRules<T>(callback: (callback: T | null) => void) {
  return (data: Response<T>) => {
    console.log(data);
    if (data.code === '00000') {
      callback(data.body);
    } else {
      callback(null);
    }
  };
}
