import store from '@/store';
import { callDelete, callGet, callPost, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export interface memberRequest {
  nickname: string;
}

export interface memberResponse {
  email: string;
}
export const memberApi = {
  memberCreate: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = '/member';
    callPost.call(addr, data, callback, errorFun);
  },
  memberLogin: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = '/memberLogin';
    callPost.call(addr, data, callback, errorFun);
  },
  memberActive: (data: object, callback: (data: boolean | unknown) => void, errorFun?: (error: object) => void) => {
    const addr = '/memberActive';
    callPut.call(addr, data, memberRules(callback), errorFun);
  },
  memberValidation: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    // alert('다음 메일 서버 오류로 이메일 전송이 불가능합니다.');
    // return;
    const addr = `/member-validation`;
    callPost.call(addr, data, callback, errorFun);
  },
  memberValidationConfirm: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/member-validation-confirm`;
    callPut.call(addr, data, callback, errorFun);
  },
  memberLogout: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/memberLogout`;
    const addStoreMember = (data: Response<object>) => {
      if (data) {
        store.commit('userInfo/addUserInfo', {});
        callback(data);
      }
    };
    callPut.call(addr, data, addStoreMember, errorFun);
  },
  member: (data: object, callback: (data: memberResponse) => void, errorFun?: (error: object) => void) => {
    const addr = '/member';
    callGet.call(addr, data, memberRules(callback), errorFun);
  },
  delete: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = '/members';
    callDelete.call(addr, data, callback, errorFun);
  },
};

export function memberRules<T>(callback: (callback: T) => void) {
  return (data: Response<T>) => {
    if (data) {
      callback(data.body);
    }
  };
}
