import * as a from 'vue';
interface popupType {
  key: string;
  addr: any;
}
const popupTypes: Array<popupType> = [];
popupTypes.push({
  key: 'memberJoin',
  addr: import('@/domain/member/popup/MemberJoinPopup.vue'),
});
popupTypes.push({
  key: 'memberModify',
  addr: import('@/domain/user/popup/MemberModifyPopup.vue'),
});
popupTypes.push({
  key: 'memberInvite',
  addr: import('@/domain/user/popup/MemberInvitePopup.vue'),
});
popupTypes.push({
  key: 'accountList',
  addr: import('@/domain/account/popup/AccountListPopup.vue'),
});
popupTypes.push({
  key: 'accountWrite',
  addr: import('@/domain/account/popup/AccountWritePopup.vue'),
});
popupTypes.push({
  key: 'accountModify',
  addr: import('@/domain/account/popup/AccountModifyPopup.vue'),
});
popupTypes.push({
  key: 'memberStatus',
  addr: import('@/domain/member/popup/MemberStatusPopup.vue'),
});
export default (name: string) => {
  const type = popupTypes.find(item => {
    return name == item.key;
  }) as popupType;
  return type;
};
