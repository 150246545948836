import { defineComponent, h, PropType } from 'vue';

import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, Plugin } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
  name: 'DoughnutChart',
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    plugins: {
      type: Array as PropType<Plugin<'doughnut'>[]>,
      default: () => [],
    },
  },
  setup(props) {
    const chartData = {
      labels: ['월급', '보너스', '용돈', '기타'],
      datasets: [
        {
          backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(201, 203, 207)'],
          data: [100, 30, 10, 10],
          // 'rgb(255, 99, 132)' 빨
          // 'rgb(255, 159, 64)' 주
          // 'rgb(255, 205, 86)' 노
          // 'rgb(75, 192, 192)' 초
          // 'rgb(54, 162, 235)' 파
          // 'rgb(153, 102, 255)' 보
          // 'rgb(201, 203, 207)' 회
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    return () =>
      h(Doughnut, {
        chartData,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins,
      });
  },
});
