
import { boardApi, BoardResponse, commentListResponse, commentResponse } from '@/domain/board/api/boardApi';
import { defineComponent, ref } from 'vue';
import router from '@/router';
import store from '@/store';

export interface comments extends commentResponse {
  isShow?: boolean;
  message: string;
}

export default defineComponent({
  name: 'DefaultLayout',
  async beforeRouteUpdate() {
    router.push({ name: 'boardList' });
  },
  async beforeCreate() {
    boardApi.boardOne(this.$props.boardKey, {}, (data: BoardResponse) => {
      console.log(data);
      if (!data) {
        router.push({ name: 'boardList' });
        return;
      }
      this.board = data;
      this.commentsCall(1);
    });
  },
  props: {
    page: {
      type: Number,
      required: false,
    },
    searchValue: {
      type: String,
      required: false,
    },
    boardKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const userInfo = ref(store.getters['userInfo/getUserInfo']);
    const { page, searchValue } = router.currentRoute.value.query;
    const commentPage = ref(1);
    const isCommentShow = ref(false);
    const board = ref({} as BoardResponse);
    const comments = ref({} as commentListResponse<comments>);
    const commentData = ref({} as comments);
    const commentsCall = (currentPage: number) => {
      boardApi.commentList(props.boardKey, { currentPage }, function (data: commentListResponse<comments>) {
        comments.value = data || {};
      });
    };
    const commentSave = (comment: comments) => {
      if (!comment.message) {
        alert('내용을 입력해주세요.');
        return;
      }
      boardApi.commentSave(props.boardKey, { ...comment, content: comment.message }, data => {
        console.log(data);
        if (data) {
          comment.message = '';
          comment.isShow = false;
          commentsCall(commentPage.value);
          //
        }
      });
    };
    const moveBoardList = () => {
      console.log(searchValue);
      console.log(page);
      router.push({ name: 'boardList', query: { searchValue, page } });
    };
    const moveBoardUpdate = () => {
      router.push({ name: 'boardUpdate' });
    };
    const moveBoardDelete = () => {
      if (!confirm('삭제 하시겠습니까?')) return;
      boardApi.delete(props.boardKey, {}, data => {
        if (data.code === '00000') {
          alert('삭제 d');
          router.push({ name: 'boardList' });
          return;
        }
      });
    };
    return {
      userInfo,
      commentData,
      commentsCall,
      commentSave,
      moveBoardList,
      moveBoardUpdate,
      moveBoardDelete,
      commentPage,
      board,
      comments,
      isCommentShow,
    };
  },
});
