
import popupList from './popupList';
import { defineAsyncComponent, defineComponent } from 'vue';
export default defineComponent({
  name: 'DefaultPopupView',
  props: { isOpen: Boolean, type: String, popupData: Object },
  setup(props, context) {
    let popupComponent = defineAsyncComponent(() => popupList(props.type as string).addr);
    const callback = (data: object) => {
      context.emit('callback', data);
      close();
    };
    const close = () => {
      context.emit('update:isOpen', false);
    };
    return {
      callback,
      close,
      popupComponent,
    };
  },
  components: {},
});
