
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { callMapping, callMappingType } from './context/callFun';
import { curry, find, map, pipe, propEq } from 'ramda';
import axios from 'axios';
import { Future } from 'fluture';
// Components
export default defineComponent({
  name: 'TestView',
  setup() {
    Future((reject, resolve) => {
      const t = setTimeout(resolve, 100, 42);
      return () => clearTimeout(t);
    });
    const addr = ref('http://localhost:8081/accountList');
    const content = ref('{"id": "  "}');
    let viewData = ref('');
    const items = Object.keys(callMapping);
    const itemSelect = ref<callMappingType>();
    const store = useStore();
    const addCounter = () => store.state.counter++;
    store.dispatch('commonCode/callApiCode');
    console.log(store.state.commonCode.code);
    setTimeout(() => {
      console.log(store.getters['commonCode/getCode'][0].commonCode);
    }, 2000);
    class Maybe {
      private $value;
      constructor(value: any) {
        this.$value = value;
      }

      static of(value: any) {
        return new Maybe(value);
      }

      get isNothing() {
        return this.$value === null || this.$value === undefined;
      }

      map(fn: (arg0: any) => any) {
        console.log('aaa');
        Maybe.of(fn(this.$value));
        return this.isNothing ? this : Maybe.of(fn(this.$value));
      }

      getOrElse(defaultValue: any) {
        return this.isNothing ? defaultValue : this.$value;
      }

      chain(fn: (arg0: any) => any) {
        return this.isNothing ? this : fn(this.$value);
      }

      toString() {
        return this.isNothing ? 'Nothing' : `Just(${this.$value})`;
      }
    }

    const getBookById = curry((books: booksType[], id: string) => {
      //  console.log(find(propEq('id', id))(books));
      //  console.log(Maybe.of(find(propEq('id', id))(books)));
      return pipe(find(propEq('id', id)), Maybe.of)(books);
    });

    const validateAuthor = (book: booksType) => {
      console.log(book);
      return book.author.indexOf('Axel') > -1 ? Maybe.of(book) : Maybe.of(null);
    };

    interface booksType {
      id: string;
      title: string;
      author: string;
    }

    const concatBooksTitle = curry((book1, book2) => {
      return `${book1.title}, ${book2.title}`;
    });

    // console.log(
    //   Maybe.of(null).map(book2 => {
    //     return concatBooksTitle('2', book2);
    //   }),
    // );

    Maybe.of({ id: 'book1', title: 'coding with javascript', author: 'Chris Minnick, Eva Holland' });

    const books = [
      { id: 'book1', title: 'coding with javascript', author: 'Chris Minnick, Eva Holland' },
      { id: 'book2', title: 'speaking javaScript', author: 'Axel Rauschmayer' },
    ] as booksType[];

    const fetchTodo = (id: string) => {
      return new Promise((reject, resolve) => {
        axios.post('http://localhost:8084/memberLogin').then(resolve).catch(reject);
      });
    };
    const fork = curry((reject: (data: any) => void, resolve: (data: any) => void, promise: Promise<any>) => {
      promise
        .then((data: any) => {
          resolve(data);
        })
        .catch((data: any) => {
          reject(data);
        });
    });
    const test = (data: any) => {
      data.then((zz: any) => {
        console.log(zz);
      });
    };
    pipe(fetchTodo, fork(console.log, console.log))('1');
    getBookById(books, 'book1')
      .chain(book1 => {
        return getBookById(books, 'book2').map(book3 => {
          return concatBooksTitle(book1, book3);
        });
      })
      .getOrElse(null); // coding with javascript, speaking javaScript

    const double = (x: Maybe) => x;
    // console.log(getBookById(books)('book2'));
    // const printAxelBookTitle = pipe(getBookById(books), map(validateAuthor));
    // console.log(printAxelBookTitle('book2'));
    console.log(map(validateAuthor, Maybe.of({ id: 'book1', title: 'coding with javascript', author: 'Chris Minnick, Eva Holland' })));
    // console.log('===============');
    // Maybe.of({ id: 'book1', title: 'coding with javascript', author: 'Chris Minnick, Eva Holland' }).map(item => {
    //   console.log(item);
    //   console.log('zzz');
    // });
    //R.map(double, { x: 1, y: 2, z: 3 }); //=> {x: 2, y: 4, z: 6}
    return {
      viewData,
      valid: true,
      addr,
      content,
      store,
      items,
      itemSelect,
      addCounter,
      call: () => {
        console.log(items[0]);
        callMapping[itemSelect.value as callMappingType].call(addr.value, content.value ? JSON.parse(content.value) : {}, (data: object) => {
          console.log(data);
          viewData.value = JSON.stringify(data);
        });
      },
    };
  },
  components: {},
});
