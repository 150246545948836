
import { defineComponent, ref } from 'vue';
import IncomeDoughnutChart from '@/domain/account/components/chart/IncomeDoughnutChart';
import ExpenseDoughnutChart from '@/domain/account/components/chart/ExpenseDoughnutChart';
import LineChart from '@/domain/account/components/chart/LineChart';
import { accountStatsApi } from '@/domain/account/api/accountStatsApi';
import store from '@/store';

export interface statsItemNicknameGroupType {
  no: number;
  profile: string;
  nickName: string;
  amount: string;
  userFk: string;
}
export interface statsItemAccountTypeGroupType {
  labels: string[];
  datasets: [
    {
      backgroundColor: string[];
      data: number[];
    },
  ];
}

export default defineComponent({
  // npm i vue-chartjs chart.js
  // name: 'DefaultLayout',
  components: { IncomeDoughnutChart, ExpenseDoughnutChart, LineChart },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return '월별 통계 데이터';
        default:
          return '고정 수지 통계 데이터';
      }
    },
  },
  setup() {
    const userInfo = ref(store.getters['userInfo/getUserInfo']);
    const toggle_one = ref(0);
    const fix_toggle_one = ref(0);
    const date = ref(new Date());
    const masks = {
      input: 'YYYY-MM',
    };
    const step = ref(1);
    const chip = ref(0);
    const accountTypeGroupColor = ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)'];
    const nicknameGroup = ref({
      fixIncome: [] as statsItemNicknameGroupType[],
      fixIncomeTotal: '0',
      fixExpense: [] as statsItemNicknameGroupType[],
      fixExpenseTotal: '0',
      income: [] as statsItemNicknameGroupType[],
      incomeTotal: '0',
      expense: [] as statsItemNicknameGroupType[],
      expenseTotal: '0',
    });
    const accountTypeGroup = ref({
      income: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      } as statsItemAccountTypeGroupType,
      expense: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      } as statsItemAccountTypeGroupType,
    });
    const searchAccountStats = (data: Date) => {
      const year = String(data.getFullYear());
      let month = String(data.getMonth() + 1);
      month = month.length === 1 ? '0' + month : month;
      const searchDate = year + month;
      chip.value = 2;
      accountStatsApi.accountType({ searchDate }, data => {
        accountTypeGroup.value.income.labels = [];
        accountTypeGroup.value.income.datasets = [
          {
            backgroundColor: [],
            data: [],
          },
        ];
        accountTypeGroup.value.expense.labels = [];
        accountTypeGroup.value.expense.datasets = [
          {
            backgroundColor: [],
            data: [],
          },
        ];
        if (data) {
          console.log(data);
          data.forEach((item, index) => {
            if (item.accountTypeName === '수입') {
              accountTypeGroup.value.income.labels.push(item.accountName);
              accountTypeGroup.value.income.datasets[0].backgroundColor.push(accountTypeGroupColor[index % accountTypeGroupColor.length]);
              accountTypeGroup.value.income.datasets[0].data.push(item.accountCount);
            } else if (item.accountTypeName === '지출') {
              accountTypeGroup.value.expense.datasets[0].data.push(item.accountCount);
              accountTypeGroup.value.expense.labels.push(item.accountName);
              accountTypeGroup.value.expense.datasets[0].backgroundColor.push(accountTypeGroupColor[index % accountTypeGroupColor.length]);
            }
          });
          console.log(accountTypeGroup.value);
        } else {
          accountTypeGroup.value.income.labels.push('없음');
          accountTypeGroup.value.income.datasets[0].backgroundColor.push(accountTypeGroupColor[0]);
          accountTypeGroup.value.income.datasets[0].data.push(1);
          accountTypeGroup.value.expense.labels.push('없음');
          accountTypeGroup.value.expense.datasets[0].backgroundColor.push(accountTypeGroupColor[0]);
          accountTypeGroup.value.expense.datasets[0].data.push(1);
        }
        chip.value = 1;
      });
      accountStatsApi.nickname({ searchDate }, data => {
        let tempFixIncomeTotal = 0;
        let tempFixExpenseTotal = 0;
        let tempIncomeTotal = 0;
        let tempExpenseTotal = 0;
        nicknameGroup.value.fixIncome = [];
        nicknameGroup.value.fixExpense = [];
        nicknameGroup.value.income = [];
        nicknameGroup.value.expense = [];
        if (data) {
          data.forEach((item, index) => {
            const customItem = {
              no: index,
              profile: 'https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg',
              nickName: item.nickname,
              userFk: item.userFk,
              amount: Number(item.money).toLocaleString('ko-KR') + '원',
            };
            if (item.accountTypeCode == '00001') {
              nicknameGroup.value.fixIncome.push(customItem);
              tempFixIncomeTotal += item.money;
            } else if (item.accountTypeCode == '00002') {
              nicknameGroup.value.fixExpense.push(customItem);
              tempFixExpenseTotal += item.money;
            } else if (item.accountTypeCode == '00003') {
              nicknameGroup.value.income.push(customItem);
              tempIncomeTotal += item.money;
            } else if (item.accountTypeCode == '00004') {
              nicknameGroup.value.expense.push(customItem);
              tempExpenseTotal += item.money;
            }
          });
          nicknameGroup.value.fixIncomeTotal = Number(tempFixIncomeTotal).toLocaleString('ko-KR');
          nicknameGroup.value.fixExpenseTotal = Number(tempFixExpenseTotal).toLocaleString('ko-KR');
          nicknameGroup.value.incomeTotal = Number(tempIncomeTotal).toLocaleString('ko-KR');
          nicknameGroup.value.expenseTotal = Number(tempExpenseTotal).toLocaleString('ko-KR');
        }
      });
    };
    searchAccountStats(date.value);
    return {
      chip,
      nicknameGroup,
      accountTypeGroup,
      toggle_one,
      fix_toggle_one,
      date,
      masks,
      step,
      searchAccountStats,
      userInfo,
    };
  },
});
