
import { defineComponent, ref } from 'vue';
import { boardApi } from '@/domain/board/api/boardApi';
import router from '@/router';
import TiptapView from '@/domain/board/components/editor/tiptap/TiptapView.vue';
// Components
export default defineComponent({
  name: 'BoardSaveView',
  async beforeRouteUpdate() {
    router.push({ name: 'boardList' });
  },
  setup() {
    const valid = ref(true);
    const form = ref();
    const formData = ref({
      content: ref(''),
      title: ref(''),
    });
    const rules = {
      titleRule: [
        (data: string) => {
          return !!data || '제목은 필수 입니다.';
        },
        (data: string) => !!data || '제목을 입력해주세요.',
      ],
    };
    const save = () => {
      form.value.validate();
      if (!formData.value.content || !formData.value.content.replace(/<[^>]*>?/g, '')) {
        alert('내용은 필수입니다.');
        return;
      }
      boardApi.save(formData.value, data => {
        if (data.code === '00000') {
          router.push({ name: 'boardList' });
        }
      });
    };
    const cancel = () => {
      router.push({ name: 'boardList' });
    };
    return {
      form,
      formData,
      rules,
      valid,
      save,
      cancel,
    };
  },
  components: { TiptapView },
});
