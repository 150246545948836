import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-block d-md-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_NavigationView = _resolveComponent("NavigationView")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_FooterView = _resolveComponent("FooterView")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_BottomNavigationView = _resolveComponent("BottomNavigationView")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "pa-0",
    style: {"background-color":"#fafafa"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderView),
      _createVNode(_component_NavigationView, {
        isDrawer: _ctx.isDrawer,
        "onUpdate:isDrawer": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDrawer) = $event)),
        class: "left__navibar--relative-md"
      }, null, 8, ["isDrawer"]),
      _createVNode(_component_v_main, { class: "main" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            fluid: "",
            style: {"min-height":"calc(100vh - 121px)"}
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }),
          _createVNode(_component_FooterView)
        ]),
        _: 3
      }),
      _createElementVNode("template", _hoisted_1, [
        _createVNode(_component_BottomNavigationView, {
          class: "bottom__navibar--relative-md",
          onOpenLeftNavigation: _ctx.openLeftNavigation
        }, null, 8, ["onOpenLeftNavigation"])
      ])
    ]),
    _: 3
  }))
}