
import { defineComponent } from 'vue';
import LayoutView from './layout/LayoutView.vue';

export default defineComponent({
  name: 'App',
  setup() {
    return {};
  },
  components: { LayoutView },
});
