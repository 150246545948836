
import { memberApi } from '@/domain/member/api/memberApi';
import router from '@/router';
import { defineComponent, ref } from 'vue';

// Components

export default defineComponent({
  name: 'SignUpView',
  setup() {
    const valid = ref(false);
    let isCodeConfirm = false;
    const form = ref();
    const isEmailAuthCall = ref(false);
    const formData = ref({
      //group: ref(''),
      email: ref(''),
      password: ref(''),
      passwordConfirm: ref(''),
      code: ref(''),
    });
    const rules = {
      emailRule: [
        (data: string) => {
          return !!data || '이메일은 필수 입니다.';
        },
        (data: string) => (data && !!data.match(/\w+@\w+\.\w+/gi)) || '이메일 형식으로 입력해주세요.',
      ],
      passwordRule: [
        (data: string) => {
          return !!data || '비밀번호는 필수 입니다.';
        },
        (data: string) => (data && !!data.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi)) || '비밀번호는 영문자 8자리 숫자 및 특수기호 1자리가 필요합니다.',
      ],
      passwordConfirmRule: [
        (data: string) => {
          return !!data || '비밀번호 확인은 필수 입니다.';
        },
        (data: string) => (data && !!data.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi) && data === formData.value.password) || '비밀번호를 확인해주세요.',
      ],
    };
    let memberValidationExpirationTime = 0;
    const memberValidation = () => {
      if (!formData.value.email.match(/\w+@\w+\.\w+/gi)) {
        alert('이메일을 입력해주세요.');
        return;
      }
      if (memberValidationExpirationTime !== 0) {
        alert('인증코드발송은 30초 마다 가능합니다.');
        return;
      }
      memberApi.memberValidation({ email: formData.value.email }, data => {
        memberValidationExpirationTime = 1;
        if (data.code === '00000') {
          alert('인증코드가 발송 되었습니다.');
          isEmailAuthCall.value = true;
          setInterval(() => {
            memberValidationExpirationTime = 0;
          }, 30000);
        } else {
          alert(data.message);
          memberValidationExpirationTime = 0;
        }
      });
    };
    const memberValidationConfirm = () => {
      memberApi.memberValidationConfirm({ email: formData.value.email, code: formData.value.code }, data => {
        if (data.code === '00000') {
          isCodeConfirm = true;
        }
        alert(data.message);
        console.log(isCodeConfirm);
      });
    };
    const createMember = () => {
      if (!isCodeConfirm) return;
      memberApi.memberCreate(formData.value, data => {
        if (data.code === '00000') {
          alert('회원가입에 성공하였습니다.');
          router.push({
            path: '/login',
          });
        } else {
          alert('회원가입에 실패하였습니다.');
        }
      });
    };
    let loading = [] as Array<boolean>;
    const load = (i: number) => {
      loading[i] = true;
      console.log(loading);
      setTimeout(() => (loading[i] = false), 3000);
    };
    return {
      isEmailAuthCall,
      memberValidation,
      memberValidationConfirm,
      createMember,
      rules,
      valid,
      form,
      formData,
      load,
      loading,
    };
  },
  methods: {
    fnCancel() {
      this.$router.push({
        path: './login',
      });
    },
  },
});
