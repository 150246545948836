import LoginView from '@/domain/member/view/LoginView.vue';
import SignUpView from '@/domain/member/view/SignUpView.vue';
import CreateMemberClassView from '@/domain/member/view/CreateMemberClassView.vue';
import InviteMemberClassView from '@/domain/member/view/InviteMemberClassView.vue';
import AccountList from '@/domain/account/view/AccountList.vue';
import AccountStatsView from '@/domain/account/view/AccountStatsView.vue';
import BoardListView from '@/domain/board/view/BoardListView.vue';
import BoardDetailView from '@/domain/board/view/BoardDetailView.vue';
import BoardSaveView from '@/domain/board/view/BoardSaveView.vue';
import BoardUpdateView from '@/domain/board/view/BoardUpdateView.vue';
import UserList from '@/domain/user/view/UserList.vue';
import Error404View from '@/domain/error/view/Error404View.vue';
import TestView from '@/api/TestView.vue';
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { classApi } from '@/domain/common/api/classApi';
import { userApi } from '@/domain/user/api/userApi';
const routes: Array<RouteRecordRaw> = [
  //...boardRouter,
  {
    // /search/screens -> /search?q=screens
    path: '/',
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/login' };
    },
  },
  {
    path: '/class/:classKey', //account-list
    name: 'class',
    redirect: () => {
      return { name: 'memberList' };
    },
    children: [
      {
        path: 'members', //account-list
        name: 'memberList',
        component: UserList,
        props: true,
        meta: { layout: 'DefaultLayout' },
      },
      {
        path: 'board',
        name: 'boardList',
        component: BoardListView,
        props: true,
        meta: { layout: 'DefaultLayout' },
        children: [
          {
            path: 'save',
            name: 'boardSave',
            component: BoardSaveView,
            props: true,
            meta: { layout: 'DefaultLayout' },
          },
          {
            path: ':boardKey',
            name: 'boardDetail',
            component: BoardDetailView,
            props: true,
            meta: { layout: 'DefaultLayout' },
          },
          {
            path: ':boardKey/update',
            name: 'boardUpdate',
            component: BoardUpdateView,
            props: true,
            meta: { layout: 'DefaultLayout' },
          },
        ],
      },
      {
        path: 'account',
        name: 'accountList',
        component: AccountList,
        meta: { layout: 'DefaultLayout' },
      },
      {
        path: 'accountStats',
        name: 'accountStats',
        component: AccountStatsView,
        meta: { layout: 'DefaultLayout' },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { layout: 'NoneLayout' },
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: SignUpView,
    meta: { layout: 'NoneLayout' },
  },
  {
    path: '/createMemberClass',
    name: 'createMemberClass',
    component: CreateMemberClassView,
    meta: { layout: 'NoneLayout' },
  },
  {
    path: '/invite/:memberClassKey',
    name: 'invite',
    component: InviteMemberClassView,
    meta: { layout: 'NoneLayout' },
    props: true,
  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
    meta: { layout: 'NoneLayout' },
  },
  {
    path: '/404',
    name: 'error404View',
    component: Error404View,
    meta: { layout: 'NoneLayout' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(from);
  console.log(to);
  new Promise((resolve, reject) => {
    const path = { ...to } as RouteLocationNormalized;
    const meta = { ...path.meta, redirect: true };
    to.matched = [to.matched[to.matched.length - 1]];
    if (to.matched[0] === undefined) {
      //인증 값이 없으면. 로그인페이지
      //인증 값이 존재시 멤버 페이지 /
      resolve('error404View');
    } else if (to.fullPath.indexOf('/class/') > -1) {
      const key = to.fullPath.replace(/\/class\/([0-9]*).*/, '$1') || to.params.classKey;
      classApi.list({}, data => {
        if (data.code === '00000') {
          const locationClassData = data.body.filter(info => {
            return info.numPk === key;
          });
          store.commit('classInfo/addClassInfo', data.body);
          if (locationClassData.length === 1) {
            store.commit('classInfo/addClassKey', key);
            resolve(path);
          } else if (data.body.length > 0) {
            store.commit('classInfo/addClassKey', data.body[0].numPk);
            resolve({ ...path, name: `class`, meta, params: { classKey: `${data.body[0].numPk}` } });
          } else {
            resolve({ ...path, name: 'login', meta });
          }
        }
      });
    } else {
      resolve(path);
    }
  }).then(param => {
    return new Promise((resolve, reject) => {
      if (!param) {
        reject();
      }
      const path = param as RouteLocationNormalized;
      const meta = { ...path.meta, redirect: true };
      if (to.fullPath.indexOf('/class/') > -1) {
        userApi.user({}, data => {
          if (data.code === '00000') {
            store.commit('userInfo/addUserInfo', data.body);
            resolve(path);
          } else {
            resolve(path);
          }
        });
      } else {
        resolve(path);
      }
    })
      .then(param => {
        if (!param) {
          throw new Error();
        }
        const path = param as RouteLocationNormalized;
        if (path.meta.redirect) {
          next(path);
        } else {
          next();
        }
      })
      .catch((e: Error) => {
        console.log(e);
        router.push({ name: 'error404View' });
      });
  });
});
export default router;
