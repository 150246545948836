import { defineComponent, h, PropType } from 'vue';

import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale, Plugin } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale);

export default defineComponent({
  name: 'LineChart',
  components: {
    Line,
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    plugins: {
      type: Array as PropType<Plugin<'line'>[]>,
      default: () => [],
    },
  },
  setup(props) {
    const chartData = {
      labels: ['2019', '2020', '2021', '2022', '2023', '2024', '2025'],
      datasets: [
        {
          label: '고정 수입',
          backgroundColor: 'rgb(54, 162, 235)',
          data: [75, 20, 42, 10, 30, 40, null],
        },
        {
          label: '고정 지출',
          backgroundColor: 'rgb(255, 99, 132)',
          data: [40, 39, 50, 40, 40, null, null],
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      // scales: {
      //   yAxes: [
      //     {
      //       stacked: false, // 쌓임
      //       display: true, // y 축 show
      //       ticks: {
      //         stepSize: 20, // 증가범위
      //         beginAtZero: true,
      //         max: 100, // 최대범위
      //         min: 0, // 최소범위
      //         padding: 10, // 오른쪽 간격
      //       },
      //       grid: {
      //         drawBorder: false,
      //         color: '#323232',
      //         lineWidth: 1,
      //       },
      //     },
      //   ],
      // },
    };
    console.dir(chartOptions);
    return () =>
      h(Line, {
        chartData,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins,
      });
  },
});
