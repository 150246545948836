
import NavigationView from './components/default/NavigationView.vue';
import HeaderView from './components/default/HeaderView.vue';
import FooterView from './components/default/FooterView.vue';
import { defineComponent, ref } from 'vue';
import BottomNavigationView from '@/layout/components/default/BottomNavigationView.vue';
// Components

export default defineComponent({
  name: 'DefaultLayout',
  components: { NavigationView, HeaderView, FooterView, BottomNavigationView },
  setup() {
    const isDrawer = ref(false);
    const openLeftNavigation = () => {
      isDrawer.value = true;
    };
    return {
      isDrawer,
      openLeftNavigation,
    };
  },
});
