import store from '@/store';
import { callDelete, callGet, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export interface userListResponse {
  numPk: string;
  createDate: string;
  nickname: string;
  description: string;
  authorityCode: string;
}

export interface userOneResponse {
  nickname: string;
  description: string;
  email: string;
  numPk: string;
}

export const userApi = {
  user: (data: object, callback: (data: Response<userOneResponse>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/user`;
    callGet.call(addr, data, callback, errorFun);
  },
  users: (data: object, callback: (data: userListResponse[] | null) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/users`;
    callGet.call(addr, data, userRules(callback), errorFun);
  },
  userOne: (key: string, data: object, callback: (data: userOneResponse | null) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/users/${key}`;
    callGet.call(addr, data, userRules(callback), errorFun);
  },
  userUpdate: (data: object, callback: (data: boolean | unknown) => void, errorFun?: (error: object) => void) => {
    const addr = '/class/{classKey}/users';
    const addStoreMember = (responseData: Response<boolean>) => {
      if (responseData) {
        store.commit('userInfo/addUserInfo', data);
        callback(responseData.body);
      }
    };
    callPut.call(addr, data, addStoreMember, errorFun);
  },
  delete: (key: string, data: object, callback: (data: Response<string>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/users/${key}`;
    callDelete.call(addr, data, callback, errorFun);
  },
};

export function userRules<T>(callback: (callback: T | null) => void) {
  return (data: Response<T>) => {
    if (data.code === '00000') {
      callback(data.body);
    } else {
      callback(null);
    }
  };
}
