
import { inviteApi, MemberClassInfoResponse } from '@/domain/user/api/invite';
import router from '@/router';
import { defineComponent, ref } from 'vue';

// Components

export default defineComponent({
  name: 'InviteMemberClassView',
  props: {
    memberClassKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const memberClassInfo = ref({} as MemberClassInfoResponse);
    inviteApi.one(props.memberClassKey, {}, data => {
      if (data.code !== '00000') {
        alert('잘못된 주소 입니다.');
        router.push('/login');
      } else {
        memberClassInfo.value = data.body;
      }
    });
    const moveMemberClass = () => {
      inviteApi.join({ memberClassKey: props.memberClassKey }, data => {
        console.log(data);
        if (data.code === '00000') {
          router.push(`/class/${memberClassInfo.value.numPk}/members`);
        } else if (data.code === '99999') {
          alert('로그인이 필요합니다.');
          router.push('/login');
        }
      });
    };
    return {
      memberClassInfo,
      moveMemberClass,
    };
  },
  methods: {
    fnCancel() {
      this.$router.push({
        path: './login',
      });
    },
  },
});
