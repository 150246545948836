import store from '@/store';
import { callDelete, callGet, callPost, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export interface BoardRequest {
  nickname: string;
}

export interface BoardResponse {
  bno: number;
  categoryName: string;
  title: string;
  createDate: string;
  count: number;
  numPk: string;
  nickName: string;
  content: string;
  userFk: string;
}

export interface BoardListResponse {
  list: BoardResponse[];
  count: number;
  page: number;
}

export interface commentRequest {
  nickname: string;
}

export interface commentResponse {
  title: string;
  depth: number;
  parentNickname: string;
  createDate: string;
  count: number;
  numPk: number;
  nickname: string;
  content: string;
  parentNum: number;
  boardFk: string;
  userFk: string;
}

export interface commentListResponse<T extends commentResponse> {
  list: T[];
  count: number;
  page: number;
}

export const boardApi = {
  boardList: (data: object, callback: (data: Response<BoardListResponse>) => void, errorFun?: (error: object) => void) => {
    const addr = '/class/{classKey}/board';
    callGet.call(addr, data, callback, errorFun);
  },
  boardOne: (key: string, data: object, callback: (data: BoardResponse) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board/${key}`;
    callGet.call(addr, data, boardRules(callback), errorFun);
  },
  save: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board`;
    callPost.call(addr, data, callback, errorFun);
  },
  update: (data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board`;
    callPut.call(addr, data, callback, errorFun);
  },
  delete: (key: string, data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board/${key}`;
    callDelete.call(addr, data, callback, errorFun);
  },
  counter: (key: string, data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board/${key}/count`;
    callPut.call(addr, data, callback, errorFun);
  },
  commentList: <T extends commentResponse>(key: string, data: object, callback: (data: commentListResponse<T>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board/${key}/comments`;
    callGet.call(addr, data, boardRules(callback), errorFun);
  },
  commentSave: (key: string, data: object, callback: (data: Response<object>) => void, errorFun?: (error: object) => void) => {
    const addr = `/class/{classKey}/board/${key}/comments`;
    callPost.call(addr, data, callback, errorFun);
  },
};

export function boardRules<T>(callback: (callback: T) => void) {
  return (data: Response<T>) => {
    if (data) {
      callback(data.body);
    }
  };
}
