import { createStore } from 'vuex';
import { accessToken, TokenModule } from '@/domain/member/store/accessToken';
import { classInfo, ClassInfoModule } from '@/domain/common/store/classInfo';
import { commonCode, CodeModule } from '@/domain/common/store/commonCode';
import { userInfo, UserInfoModule } from '@/domain/user/store/userInfo';

export interface RootState {
  commonCode: CodeModule;
  accessToken: TokenModule;
  classInfo: ClassInfoModule;
  userInfo: UserInfoModule;
}

export default createStore({
  modules: { commonCode, accessToken, classInfo, userInfo },
});
