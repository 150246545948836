import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface TokenModule {
  token: string;
}

export const accessToken: Module<TokenModule, RootState> = {
  namespaced: true,
  state: () => ({
    token: '',
  }),
  mutations: {
    addToken(state, payload) {
      state.token = payload;
    },
  },
  getters: {
    getToken(state, getters, rootState) {
      // 다른 store state 추론 가능
      return state.token;
    },
  },
  actions: {
    callApiCode({ state, commit, rootState }) {
      // callMapping.callGetList.call('http://localhost:8084/commonCodeList', {}, data => {
      //   commit('addToken', data);
      // });
    },
  },
};
