import { classApi, MemberClassInfoResponse } from '@/domain/common/api/classApi';
import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface ClassInfoModule {
  classKey: string;
  classInfo: MemberClassInfoResponse[];
}

export const classInfo: Module<ClassInfoModule, RootState> = {
  namespaced: true,
  state: () => ({
    classKey:
      location.pathname.split('/').filter((data, index, origin) => {
        return origin[index - 1] === 'class';
      })[0] || '',
    classInfo: [],
  }),
  mutations: {
    addClassInfo(state, classInfo) {
      state.classInfo = classInfo;
    },
    addClassKey(state, classKey) {
      state.classKey = classKey;
    },
  },
  getters: {
    getClassInfo(state, getters, rootState) {
      return state.classInfo;
    },
    getClassKey(state, getters, rootState) {
      return state.classKey;
    },
    getFirstClass(state, getters, rootState) {
      console.log(state.classInfo);
      return state.classInfo.find(() => {
        return true;
      });
    },
  },
  actions: {
    callClassKey({ state, commit, rootState }) {
      classApi.list({}, data => {
        commit('addClassInfo', data);
      });
    },
  },
};
