import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface UserInfoModule {
  userInfo: {
    photo: string;
    nickname: string;
    email: string;
    description: string;
    authorityCode: string;
  };
}

export const userInfo: Module<UserInfoModule, RootState> = {
  namespaced: true,
  state: () => ({
    userInfo: {
      photo: 'https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg',
      nickname: '',
      email: '',
      description: '',
      authorityCode: '',
    },
  }),
  mutations: {
    addUserInfo(state, payload) {
      console.log(payload);
      state.userInfo = { ...state.userInfo, ...payload };
      console.log(state);
    },
  },
  getters: {
    getUserInfo(state, getters, rootState) {
      console.log(state.userInfo);
      return state.userInfo;
    },
  },
  actions: {
    // callMemberInfo({ state, commit, rootState }) {
    //   return new Promise((resolve, reject) => {
    //     memberApi.memberOne(rootState.classInfo.classKey, {}, (data: memberResponse) => {
    //       commit('addMemberInfo', data);
    //       resolve(data);
    //     });
    //   });
    // },
  },
};
