
import { classApi } from '@/domain/common/api/classApi';
import { memberApi } from '@/domain/member/api/memberApi';
import DefaultPopupView from '@/components/popup/DefaultPopupView.vue';
import router from '@/router';
import store from '@/store';
import { userInfo } from '@/domain/user/store/userInfo';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'HeaderView',
  beforeCreate() {
    this.classList = store.getters['classInfo/getClassInfo'];
    this.classData = store.getters['classInfo/getClassKey'];
  },
  setup() {
    const classList = store.getters['classInfo/getClassInfo'];
    const classData = ref(store.getters['classInfo/getClassKey']);
    const popupType = ref('');
    const isPopupOpen = ref({
      isMemberModifyOpen: false,
      isMemberInviteOpen: false,
    });
    const classMove = (value: number) => {
      router.push({ name: 'memberList', params: { classKey: value } });
    };
    const logout = () => {
      if (confirm('로그아웃을 하시겠습니까?')) {
        memberApi.memberLogout({}, data => {
          if (data.code === '00000') {
            router.push('/login');
          } else {
            alert('로그아웃에 실패 하였습니다');
          }
        });
      }
    };
    const refresh = () => {
      router.go(0);
    };
    return {
      isPopupOpen,
      logout,
      popupType,
      classList,
      classData,
      classMove,
      refresh,
    };
  },
  components: { DefaultPopupView },
});
