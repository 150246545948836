
import { boardApi, BoardListResponse } from '@/domain/board/api/boardApi';
import router from '@/router';
import { curry } from 'ramda';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Response } from '@/api/context/Call';
// Components

export default defineComponent({
  name: 'BoardListView',
  async beforeRouteUpdate(to, from) {
    console.log(`boardList routeUpdate ${to.query}`);
    console.log(to.query);
    const { page, searchValue } = to.query;
    this.callBoardList(String(searchValue) ?? '', Number(page) ?? 1);
  },
  props: {
    page: {
      type: Number,
      required: false,
    },
    searchValue: {
      type: String,
      required: false,
    },
  },
  setup() {
    console.log(router.currentRoute.value.query);
    const { page, searchValue } = router.currentRoute.value.query;
    console.log(page);
    console.log(searchValue);
    const currentPage = ref((page && Number(page)) || 1);
    const title = ref((searchValue && String(searchValue)) || '');
    const boardList = ref({} as BoardListResponse);
    const callBoardList = (searchValue: string, currentPage: number) => {
      console.log(`boardListCall =>`);
      boardApi.boardList({ currentPage, searchValue }, data => {
        console.log(data);
        window.scrollTo(0, 0);
        boardList.value = data.body ?? {};
        if (!data.body) {
          boardList.value.page = 1;
          currentPage = 1;
        }
        console.log(boardList.value);
      });
    };
    const movePage = (searchValue: string, page: number) => {
      router.push({ name: 'boardList', query: { searchValue, page } });
    };
    const searchTitle = (searchValue: string) => {
      router.push({ name: 'boardList', query: { searchValue, page: 1 } });
      currentPage.value = 1;
    };
    const moveSave = () => {
      router.push({ name: 'boardSave' });
    };
    const moveDetail = (boardKey: string) => {
      boardApi.counter(boardKey, {}, data => {
        if (data.code === '00000') {
          router.push({ name: 'boardDetail', params: { boardKey }, query: { searchValue, page: 1 } });
        }
      });
    };
    callBoardList(title.value, currentPage.value);
    return {
      title,
      movePage,
      boardList,
      currentPage,
      callBoardList,
      moveSave,
      searchTitle,
      moveDetail,
    };
  },
});
