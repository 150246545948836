
import { defineComponent, ref } from 'vue';
import { boardApi, BoardResponse } from '@/domain/board/api/boardApi';
import router from '@/router';
import TiptapView from '@/domain/board/components/editor/tiptap/TiptapView.vue';
// Components
export default defineComponent({
  name: 'BoardUpdateView',
  props: {
    boardKey: {
      type: String,
      required: true,
    },
  },
  async beforeRouteUpdate() {
    router.push({ name: 'boardList' });
  },
  setup(props) {
    const valid = ref(true);
    const form = ref();
    const formData = ref({
      content: ref(''),
      title: ref(''),
      key: props.boardKey,
    });
    boardApi.boardOne(props.boardKey, {}, function (data: BoardResponse) {
      console.log(data);
      formData.value.title = data.title;
      formData.value.content = data.content;
    });
    const rules = {
      titleRule: [
        (data: string) => {
          return !!data || '제목은 필수 입니다.';
        },
        (data: string) => !!data || '제목을 입력해주세요.',
      ],
    };
    const update = () => {
      form.value.validate();
      console.log(formData.value.content);
      if (!formData.value.content || !formData.value.content.replace(/<[^>]*>?/g, '')) {
        alert('내용은 필수입니다.');
        return;
      }
      boardApi.update(formData.value, data => {
        if (data.code === '00000') {
          router.push({ name: 'boardDetail' });
        }
      });
    };
    const cancel = () => {
      router.push({ name: 'boardList' });
    };
    return {
      form,
      formData,
      rules,
      valid,
      update,
      cancel,
    };
  },
  components: { TiptapView },
});
