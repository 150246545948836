import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_DefaultPopupView = _resolveComponent("DefaultPopupView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_navigation_drawer, {
      "model-value": _ctx.$props.isDrawer || !_ctx.isMobile,
      temporary: "",
      "onUpdate:modelValue": _ctx.closeDrawer,
      "expand-on-hover": "",
      rail: !_ctx.isMobile,
      scrim: _ctx.isMobile
    }, {
      append: _withCtx(() => [
        _createVNode(_component_v_divider),
        _createVNode(_component_v_list, {
          density: "compact",
          nav: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appendMenus, (menu) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: menu.key,
                "prepend-icon": menu.icon,
                title: menu.title,
                onClick: 
            () => {
              _ctx.clickMenu(menu.key);
            }
          
              }, null, 8, ["prepend-icon", "title", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPopupOpen.isMemberStatusOpen = true)),
              "prepend-icon": "mdi-face-man-shimmer",
              title: _ctx.userData.nickname,
              subtitle: _ctx.userData.email
            }, null, 8, ["title", "subtitle"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider),
        _createVNode(_component_v_list, {
          density: "compact",
          nav: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: menu.key,
                "prepend-icon": menu.icon,
                title: menu.title,
                onClick: 
          () => {
            _ctx.clickMenu(menu.key);
          }
        
              }, null, 8, ["prepend-icon", "title", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value", "onUpdate:modelValue", "rail", "scrim"]),
    _createVNode(_component_DefaultPopupView, {
      isOpen: _ctx.isPopupOpen.isMemberModifyOpen,
      "onUpdate:isOpen": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isPopupOpen.isMemberModifyOpen) = $event)),
      type: "memberModify"
    }, null, 8, ["isOpen"]),
    _createVNode(_component_DefaultPopupView, {
      isOpen: _ctx.isPopupOpen.isMemberStatusOpen,
      "onUpdate:isOpen": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isPopupOpen.isMemberStatusOpen) = $event)),
      type: "memberStatus"
    }, null, 8, ["isOpen"])
  ], 64))
}