
import DefaultPopupView from '@/components/popup/DefaultPopupView.vue';
import { userApi } from '@/domain/user/api/userApi';
import router from '@/router';
import store from '@/store';
import { computed, defineComponent, ref } from 'vue';
import vuetify from '@/plugins/vuetify';
import { is } from 'ramda';
interface menus {
  key: string;
  title: string;
  name: string;
  icon?: string;
}
export default defineComponent({
  name: 'NavigationView',
  components: { DefaultPopupView },
  props: {
    isDrawer: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const isMobile = ref(window.innerWidth < 959);
    const naviInfo = ref({} as { rail?: boolean });
    window.addEventListener(
      'resize',
      () => {
        isMobile.value = window.innerWidth < 959;
        isMobile.value ? (naviInfo.value = {}) : (naviInfo.value = { rail: false });
        naviInfo.value = { rail: false };
      },
      { passive: true },
    );
    const isPopupOpen = ref({
      isMemberModifyOpen: false,
      isMemberStatusOpen: false,
    } as { [key: string]: boolean });
    const closeDrawer = () => {
      context.emit('update:isDrawer', false);
      return false;
    };
    const userData = computed(() => store.getters['userInfo/getUserInfo']);
    const menus = ref([
      {
        type: 'page',
        key: 'index',
        name: 'memberList',
        title: '가족구성원',
        icon: 'mdi-account-multiple-outline',
      },
      {
        type: 'page',
        key: 'board',
        name: 'boardList',
        title: '게시판',
        icon: 'mdi-pencil-outline',
      },
      {
        type: 'page',
        key: 'account',
        name: 'accountList',
        title: '가계부',
        icon: 'mdi-calendar-text-outline',
      },
      {
        type: 'page',
        key: 'accountStats',
        name: 'accountStats',
        title: '가계부 통계',
        icon: 'mdi-chart-box-outline',
      },
    ]);
    const appendMenus = ref([
      {
        type: 'popup',
        key: 'memberModify',
        name: 'isMemberModifyOpen',
        title: '정보수정',
        icon: 'mdi-account-outline',
      },
      {
        type: 'page',
        key: 'createMemberClass',
        name: 'createMemberClass',
        title: '방생성',
        icon: 'mdi-plus',
      },
    ]);
    const clickMenu = (key: string) => {
      const menu = [...menus.value, ...appendMenus.value].find((menu: menus) => {
        return menu.key === key;
      });
      if (menu) {
        if (menu.type === 'page') {
          router.push({ name: menu.name });
        } else if (menu.type === 'popup') {
          isPopupOpen.value[menu.name] = true;
        }
      }
    };
    return {
      naviInfo,
      isMobile,
      userData,
      clickMenu,
      isPopupOpen,
      menus,
      appendMenus,
      closeDrawer,
    };
  },
});
