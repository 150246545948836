import router from '@/router';
import store from '@/store';
import axios, { AxiosRequestHeaders } from 'axios';

let refreshTokenPromise: Promise<void> | null = null;
const headers: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  Accept: '*/*',
  crossDomain: true,
  withCredentials: true,
};

export default function responseCodeRules(detailCode: string, callback: () => void) {
  if (detailCode === '90100') {
    refreshCall()?.then(() => {
      callback();
    });
    return false;
  } else if (detailCode === '90202') {
    router.push('/404');
  }
  return true;
}

async function refreshCall() {
  if (refreshTokenPromise === null) {
    refreshTokenPromise = axios
      .put(`${process.env.VUE_APP_SERVER_CALL_URL}/refresh`, {}, { headers })
      .then(response => {
        refreshTokenPromise = null;
        if (response.headers.authorization) {
          store.commit('accessToken/addToken', response.headers.authorization);
        } else {
          throw new Error('not Auth');
        }
      })
      .catch((e: Error) => {
        router.push('/login');
      });
    return refreshTokenPromise;
  } else {
    await refreshTokenPromise;
  }
}
