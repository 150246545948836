
import { classApi } from '@/domain/common/api/classApi';
import { memberApi } from '@/domain/member/api/memberApi';
import router from '@/router';
import store from '@/store';
import { defineComponent, ref } from 'vue';

// Components

export default defineComponent({
  name: 'CreateMemberClassView',
  setup() {
    const valid = ref(false);
    const form = ref();
    const formData = ref({
      //group: ref(''),
      className: ref(''),
    });
    const rules = {
      classNameRules: [
        (data: string) => {
          return !!data || '방이름은 필수 입니다.';
        },
      ],
    };
    const createMemberClass = () => {
      form.value.validate();
      classApi.create(formData.value, data => {
        if (data) {
          store.dispatch('classInfo/callClassKey');
          store.commit('classInfo/addClassKey', data.memberClassKey);
          router.push({
            path: `/class/${data.memberClassKey}/members`,
          });
        } else {
          alert('방생성에 실패하였습니다.');
        }
      });
    };
    const moveBack = () => {
      // router.push({
      //   path: './login',
      // });
      router.go(-1);
    };
    let loading = [] as Array<boolean>;
    const load = (i: number) => {
      loading[i] = true;
      console.log(loading);
      setTimeout(() => (loading[i] = false), 3000);
    };
    return {
      createMemberClass,
      moveBack,
      rules,
      valid,
      form,
      formData,
      load,
      loading,
    };
  },
});
