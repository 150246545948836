import { callGet, callPost, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export const inviteApi = {
  one: (key: string, data: object, callback: (data: Response<MemberClassInfoResponse>) => void, errorFun?: (error: object) => void) => {
    const addr = `/invite/${key}`;
    callGet.call(addr, data, callback, errorFun);
  },
  join: (data: object, callback: (data: Response<MemberClassInviteResponse>) => void, errorFun?: (error: object) => void) => {
    const addr = `/invite`;
    callPost.call(addr, data, callback, errorFun);
  },
  list: (data: object, callback: (data: Response<MemberClassInfoResponse>) => void, errorFun?: (error: object) => void) => {
    //[]
    const addr = `/invite`;
    callGet.call(addr, data, callback, errorFun);
  },
  invite: (data: object, callback: (data: Response<MemberClassInfoResponse>) => void, errorFun?: (error: object) => void) => {
    // alert('다음 메일 서버 오류로 이메일 전송이 불가능합니다.');
    // return;
    const addr = `/invite/{classKey}`;
    callPost.call(addr, data, callback, errorFun);
  },
};

export interface MemberClassInfoResponse {
  numPk: string;
  className: string;
}

export interface MemberClassInviteResponse {
  memberClassKey: string;
}

export function inViteApiRules<T>(callback: (callback: T | null) => void) {
  return (data: Response<T>) => {
    if (data.code === '99999') {
      callback(null);
    } else {
      callback(data.body);
    }
  };
}
