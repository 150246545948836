
import DefaultPopupView from '@/components/popup/DefaultPopupView.vue';
import { userApi, userListResponse } from '@/domain/user/api/userApi';
import store from '@/store';
import { computed, defineComponent, ref } from 'vue';
import { add, curry, evolve, map, pick, pipe, toUpper } from 'ramda';
import { fork, Future } from '@/context/monad/fluture';
import { Either } from '@/context/monad/Either';
// import { Just } from '@/context/monad/maybe/Just';
// Components

export default defineComponent({
  components: { DefaultPopupView },
  name: 'GroupMembersView',
  async beforeRouteUpdate(to, from) {
    userApi.users({}, (users: userListResponse[] | null) => {
      if (users) {
        this.formData = users;
      }
    });
    this.userInfo = store.getters['userInfo/getUserInfo'];
  },
  setup() {
    // console.log(
    //   Just.of(100)
    //     .map(data => data + 1)
    //     .getOrElse(1),
    // ); // 100
    // console.log(Nothing.of(100).chain(Just.of).getOrElse(1)); // 100
    const test2 = () => {
      const log = curry((label, val) => {
        console.log(label, val);
      });
      const validateId = (id: number) => {
        return typeof id !== 'number' ? Either.left(`TODO ID가 숫자가 아닙니다: ${id}`) : id < 1 || id > 200 ? Either.left(`TODO ID가 유효 범위에 있지 않습니다: ${id}`) : Either.right(id);
      };
      const fetchTodo = (id: number) => {
        return Future((reject, resolve) => {
          fetch(`https://jsonplaceholder.typicode.com/todos/${id}`)
            .then(response => response.json())
            .then(resolve)
            .catch(reject);
          return () => console.log('취소 콜백');
        });
      };
      const test = () => {
        // map(
        //   console.log,
        //   fetch(`https://jsonplaceholder.typicode.com/todos/1`)
        //     .then(response => response.json())
        //     .finally(data => {
        //       return data;
        //     }),
        // );
      };
      test();
      const getTodoTitleAndCompleted = (id: number) => {
        console.log(fetchTodo(id));
        return map(
          getTitleAndCompleted,
          Future((reject, resolve) => {
            fetch(`https://jsonplaceholder.typicode.com/todos/${id}`)
              .then(response => {
                console.log(response);
                return response.json();
              })
              .then(resolve)
              .catch(reject);
            return () => console.log('취소 콜백');
          }),
        );
      };
      const getTitleAndCompleted = (data: any) => {
        console.log(data);
        return pipe(
          pick(['title', 'completed']), // {title: 'abc', completed: false}
          evolve({ title: toUpper }), // {title: 'ABC', completed: false}
        )(data);
      };
      const either = curry((l, r, e) => {
        console.log(e);
        return e.isLeft ? l(e.$value) : r(e.$value);
      });

      either(log('유효하지 않은 TODO ID'), pipe(getTodoTitleAndCompleted, fork(log('요청 실패'), log('TODO 결과'))), validateId(1));
      //
    };

    const findBookById = curry((id: string, books: Array<{ id: string }>) => {
      return books.find(book => book.id === id);
    });
    // map(1, Maybe.of(1));
    // const a = pipe(map([], Maybe.of(1)), (maybe: Maybe) => maybe.getOrElse)([{ id: '1' }]); // Ramda map + 2: Just(44)
    // console.log(a);
    // const map = curry((fn, functor) => {
    //   return functor.map(fn);
    // });
    // const log = curry((label, value) => {
    //   console.log(label, value);
    // });

    // pipe(Maybe.of, map(add(2)), log('Ramda map + 2: '))(42); // Ramda map + 2: Just(44)
    // //
    const isPopupOpen = ref({
      isMemberModifyOpen: false,
    } as { [key: string]: boolean });
    const userInfo = ref(store.getters['userInfo/getUserInfo']);
    const formData = ref([
      {
        numPk: '',
        nickname: '',
        description: '',
        authorityCode: '',
      },
    ]);
    const userListCall = () => {
      userApi.users({}, (users: userListResponse[] | null) => {
        if (users) {
          formData.value = users;
        }
      });
    };
    const openUserPopup = (userKey: string) => {
      isPopupOpen.value.isMemberModifyOpen = true;
    };
    userListCall();
    const userDeleteCall = (userKey: string) => {
      if (confirm('강퇴 시키겠습니까?')) {
        userApi.delete(userKey, {}, data => {
          if (data.code === '00000') {
            userListCall();
          }
        });
      }
    };
    return {
      openUserPopup,
      isPopupOpen,
      userInfo,
      formData,
      userDeleteCall,
    };
  },
});
