
import { classApi } from '@/domain/common/api/classApi';
import { inviteApi } from '@/domain/user/api/invite';
import { memberApi } from '@/domain/member/api/memberApi';
import router from '@/router';
import store from '@/store';
import { defineComponent, ref } from 'vue';

// Components

export default defineComponent({
  name: 'LoginView',
  mounted() {
    process.env.VUE_APP_TEST_EMAIL && this.form.validate();
  },
  setup() {
    const valid = ref(false);
    const form = ref();
    const formData = ref({
      email: ref(process.env.VUE_APP_TEST_EMAIL || ''),
      password: ref(process.env.VUE_APP_TEST_PASSWORD || ''),
    });
    const login = () => {
      memberApi.memberLogin(formData.value, data => {
        if (data.code !== '00000') {
          alert(data.message);
          return;
        }
        inviteApi.list({}, data => {
          console.log(data);
          if (data.body) {
            if (confirm(`${data.body.className}방에 대한 초대이력이 있습니다. 참가하시겠습니까?`)) {
              inviteApi.join({ memberClassKey: data.body.numPk }, data => {
                if (data.code === '00000') {
                  alert('초대된 방에 참가 되었습니다.');
                  router.push({
                    path: `/class/${data.body.memberClassKey}/members`, //방생성 화면으로 이동
                  });
                }
              });
            } else {
              nextMove();
            }
          } else {
            nextMove();
          }
        });
      });
    };
    const nextMove = () => {
      classApi.list({}, data => {
        if (data.code === '00000' && data.body.length > 0) {
          store.commit('classInfo/addClassInfo', data);
          store.commit('classInfo/addClassKey', data.body[0].numPk);
          router.push({
            path: `/class/${data.body[0].numPk}/members`,
          });
        } else {
          router.push({
            path: `/createMemberClass`, //방생성 화면으로 이동
          });
        }
      });
    };
    const rules = {
      emailRule: [
        (data: string) => {
          return !!data || '이메일은 필수 입니다.';
        },
        (data: string) => (data && !!data.match(/\w+@\w+\.\w+/gi)) || '이메일 형식으로 입력해주세요.',
      ],
      passwordRule: [
        (data: string) => {
          return !!data || '비밀번호는 필수 입니다.';
        },
      ],
    };
    return {
      rules,
      form,
      login,
      formData,
      valid,
    };
  },
  methods: {
    fnSignUp() {
      this.$router.push({
        path: './signUp',
      });
    },
  },
});
