import store from '@/store';
import { callGet, callPost, callPut } from '@/api/context/callFun';
import { Response } from '@/api/context/Call';

export const classApi = {
  list: (data: object, callback: (data: Response<MemberClassListResponse[]>) => void, errorFun?: (error: object) => void) => {
    const addr = '/class';
    callGet.call(addr, data, callback, errorFun);
  },
  one: (data: object, callback: (data: MemberClassListResponse[] | null) => void, errorFun?: (error: object) => void) => {
    const addr = '/class';
    callGet.call(addr, data, classRules(callback), errorFun);
  },
  create: (data: object, callback: (data: CreateMemberClassResponse | null) => void, errorFun?: (error: object) => void) => {
    const addr = '/class';
    callPost.call(addr, data, classRules(callback), errorFun);
  },
};
export interface MemberClassListResponse {
  numPk: string;
  className: string;
  userFk: string;
}

export interface CreateMemberClassResponse {
  memberClassKey: string;
}

export interface MemberClassInfoResponse {
  numPk: string;
  className: string;
}

export function classRules<T>(callback: (callback: T | null) => void) {
  return (data: Response<T>) => {
    if (data.code === '00000') {
      callback(data.body);
    } else {
      callback(null);
    }
  };
}
