
import router from '@/router';
import store from '@/store';
import { defineComponent } from 'vue';

// Components

export default defineComponent({
  name: 'LoginView',
  setup() {
    const backMove = () => {
      router.push({ name: 'class', params: { classKey: '99999999' } });
      //최신 방으로이동
    };
    return {
      backMove,
    };
  },
});
