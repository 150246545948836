
import DefaultPopupView from '@/components/popup/DefaultPopupView.vue';
import router from '@/router';
import { defineComponent, ref } from 'vue';
interface menus {
  key: string;
  title: string;
  name: string;
  icon?: string;
}
export default defineComponent({
  name: 'BottomNavigationView',
  components: { DefaultPopupView },
  setup(props, context) {
    const isPopupOpen = ref({
      isMemberModifyOpen: false,
      isMemberStatusOpen: false,
    } as { [key: string]: boolean });
    interface menuType {
      type: string;
      group: string;
      key: string;
      name: string;
      title: string;
      icon: string;
    }
    const openLeftNavigation = () => {
      context.emit('openLeftNavigation', true);
    };
    interface groupMenyType {
      [group: string]: menuType[];
    }
    const menus = ref([
      {
        type: 'popup',
        group: 'member',
        key: 'memberModify',
        name: 'isMemberModifyOpen',
        title: '정보수정',
        icon: 'mdi-account-outline',
      },
    ] as menuType[]);
    const groupMenus = menus.value.reduce((acc: groupMenyType, cur: menuType) => {
      if (!acc[cur.group]) acc[cur.group] = [];
      acc[cur.group].push(cur);
      return acc;
    }, {});
    const clickMenu = (key: string) => {
      const menu = menus.value.find((menu: menus) => {
        return menu.key === key;
      });
      if (menu) {
        if (menu.type === 'page') {
          router.push({ name: menu.name });
        } else if (menu.type === 'popup') {
          isPopupOpen.value[menu.name] = true;
        }
      }
    };
    return {
      openLeftNavigation,
      groupMenus,
      clickMenu,
      isPopupOpen,
      menus,
    };
  },
});
