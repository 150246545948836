// import { maybe } from './../../context/monad/Maybe';
import { Either } from '@/context/monad/Either';
import { fork, Future } from '@/context/monad/fluture';
import { IFunctor } from '@/context/monad/interfaces';
import router from '@/router';
import store from '@/store';
import { injectionAddressValue } from '@/util/stringUtil';
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { value } from 'fluture';
import { curry, evolve, Functor, map, pick, pipe, toUpper } from 'ramda';
import responseCodeRules from './responseCodeRules';
// import { Just } from '@/context/monad/maybe/Just';

const callMapping = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
} as const;

export interface Response<T> {
  code: string;
  detailCode: string;
  message: string;
  body: T;
  status: string;
  name: string;
}

export type callType = keyof typeof callMapping;

axios.defaults.withCredentials = true;
const headers: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  Accept: '*/*',
  crossDomain: true,
  withCredentials: true,
};
export default abstract class Call {
  protected request(): void {
    // test2();
    const token = store.getters['accessToken/getToken'];
    const method = this.getMethod();
    let addr = this.getAddr();
    const callData = this.getData();
    const callback = this.getCallback();
    const errorFun = this.getErrorFun();
    if (token) {
      headers.Authorization = token;
    }
    if (addr.match(/{classKey}/) !== null) {
      addr = injectionAddressValue(addr, 'classKey', store.getters['classInfo/getClassKey']);
    }
    if (addr.indexOf('//') > -1) {
      console.error(addr);
      console.error('잘못된 요청입니다.');
      router.push('/404');
      return;
    }
    addr = process.env.VUE_APP_SERVER_CALL_URL + addr;
    console.log(addr);
    this.customAxiosCall(method, addr, callData, headers)
      .then(response => {
        const is = responseCodeRules(response.data.detailCode, () => {
          headers.Authorization = store.getters['accessToken/getToken'];
          this.customAxiosCall(method, addr, callData, headers).then(response => {
            if (callback) {
              callback(response.data);
            }
          });
        });
        if (is) {
          //로그인처리떄 뺴기
          if (response.headers.authorization) {
            store.commit('accessToken/addToken', response.headers.authorization);
          }
          if (callback) {
            callback(response.data);
          }
        }
      })
      .catch(function (error) {
        if (errorFun) {
          errorFun(error);
        }
      });
  }
  customAxiosCall(method: callType, addr: string, callData: object, headers: AxiosRequestHeaders): Promise<AxiosResponse<Response<object>>> {
    if (method === 'get' || method === 'delete') {
      return axios[callMapping[method]](addr, { headers });
    } else {
      return axios[callMapping[method]](addr, callData, { headers });
    }
  }
  abstract getMethod(): callType;
  abstract getAddr(): string;
  abstract getData(): object;
  abstract getCallback<T>(): ((data: Response<T>) => void) | undefined;
  abstract getErrorFun(): ((error: object) => void) | undefined;
}

//
//
//
const test2 = () => {
  const log = curry((label, val) => {
    console.log(label, val);
  });
  const validateId = (id: number) => {
    return typeof id !== 'number' ? Either.left(`TODO ID가 숫자가 아닙니다: ${id}`) : id < 1 || id > 200 ? Either.left(`TODO ID가 유효 범위에 있지 않습니다: ${id}`) : Either.right(id);
  };
  const fetchTodo = (id: number) => {
    return Future((reject, resolve) => {
      fetch(`https://jsonplaceholder.typicode.com/todos/${id}`)
        .then(response => response.json())
        .then(resolve)
        .catch(reject);
      return () => console.log('취소 콜백');
    });
  };
  const test = () => {
    // map(
    //   console.log,
    //   fetch(`https://jsonplaceholder.typicode.com/todos/1`)
    //     .then(response => response.json())
    //     .finally(data => {
    //       return data;
    //     }),
    // );
  };
  test();
  const getTodoTitleAndCompleted = (id: number) => {
    console.log(fetchTodo(id));
    return map(
      getTitleAndCompleted,
      Future((reject, resolve) => {
        fetch(`https://jsonplaceholder.typicode.com/todos/${id}`)
          .then(response => {
            console.log(response);
            return response.json();
          })
          .then(resolve)
          .catch(reject);
        return () => console.log('취소 콜백');
      }),
    );
  };
  const getTitleAndCompleted = (data: any) => {
    console.log(data);
    return pipe(
      pick(['title', 'completed']), // {title: 'abc', completed: false}
      evolve({ title: toUpper }), // {title: 'ABC', completed: false}
    )(data);
  };
  const either = curry((l, r, e) => {
    console.log(e);
    return e.isLeft ? l(e.$value) : r(e.$value);
  });

  either(log('유효하지 않은 TODO ID'), pipe(getTodoTitleAndCompleted, fork(log('요청 실패'), log('TODO 결과'))), validateId(1));
  //
};

// const a = pipe(Maybe.of, data => String(data))(1);
// console.log(a);

//| { map: <B>(fn: (a: A) => B) => Functor<B>; [key: string]: any };

// const Functor2 = <T>{
//   value,
//   mapx: <R>(fn: (a: T) => R) => Functor2(fn(value))
// };
// //:Functor<T>
// const testFun = {
//   value,
//   map<R>: (fn: (a: T) => R) => testFun(fn(value))
// };

// map<U>(fn: (x: T) => U) {
//   return new maybe<U>(fn(this.value()));
// }
// const Maybe = <A>(value: A): Functor<A> => ({
//   value,
//   ap: <U>(param: U): Functor<U> | undefined => {
//     if (value instanceof Function) {
//       return Maybe(value(param));
//     }
//   },
//   map: <U>(fn: (a: A) => U): Functor<U> => {
//     return Maybe(fn(value));
//   },
// });
class Maybe<T> implements IFunctor<T> {
  constructor(private _value: T) {}
  private value() {
    return this._value;
  }
  map<U>(fn: (a: T) => U): Maybe<U> {
    return Maybe.of(fn(this.value()));
  }
  static of<U>(value: U): Maybe<U> {
    return new Maybe(value);
  }
}

const aa = curry((data: number) => data + 1);
// console.log(Maybe(aa).ap(123));
// Maybe.of(1);
// console.log(map(aa, Maybe.of(1)));
// console.log(pipe(Maybe.of, aa)(1));

// '{ [key: string]: any; "fantasy-land/map":
//  <B>(fn: (a: number) => B) => Functor<B>; }

// Maybe(1).map(aa);
// map(aa, Maybe(1));
// pipe(Maybe, map(aa))(1);
