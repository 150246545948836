import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
loadFonts();

createApp(App).use(router).use(store).use(vuetify).use(VCalendar, {}).mount('#app');
