
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from './DefaultLayout.vue';
import NoneLayout from './NoneLayout.vue';
// Components

export default defineComponent({
  name: 'LayoutView',
  setup() {
    const route = useRoute();
    const layout = computed(() => route.meta.layout);
    return {
      layout,
    };
  },
  components: { DefaultLayout, NoneLayout },
});
