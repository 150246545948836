import { createQueryString } from '@/util/stringUtil';
import Call, { callType, Response } from './Call';

class CallGet extends Call {
  private method: callType = 'get';
  private addr: string | undefined;
  private callData = {};
  private callback: ((data: Response<any>) => void) | undefined;
  private errorFun: ((error: object) => void) | undefined;
  call<T>(addr: string, callData: object, callback?: (data: Response<T>) => void, errorFun?: (error: object) => void): void {
    addr += createQueryString(callData);
    this.getMethod();
    this.addr = addr;
    this.callData = callData;
    this.callback = callback;
    this.errorFun = errorFun;
    this.request();
  }
  getMethod(): callType {
    return this.method;
  }
  getAddr(): string {
    if (!this.addr) {
      throw new Error('호출 주소가 정의 되어있지 않습니다.');
    }
    return this.addr;
  }
  getData(): object {
    return this.callData;
  }
  getCallback<T>(): ((data: Response<T>) => void) | undefined {
    return this.callback;
  }
  getErrorFun(): ((error: object) => void) | undefined {
    return this.errorFun;
  }
}

class CallPost extends Call {
  private method: callType = 'post';
  private addr: string | undefined;
  private callData = {};
  private callback: ((data: Response<any>) => void) | undefined;
  private errorFun: ((error: object) => void) | undefined;
  call<T>(addr: string, callData: object, callback?: (data: Response<T>) => void, errorFun?: (error: object) => void): void {
    this.getMethod();
    this.addr = addr;
    this.callData = callData;
    this.callback = callback;
    this.errorFun = errorFun;
    this.request();
  }
  getMethod(): callType {
    return this.method;
  }
  getAddr(): string {
    if (!this.addr) {
      throw new Error('호출 주소가 정의 되어있지 않습니다.');
    }
    return this.addr;
  }
  getData(): object {
    return this.callData;
  }
  getCallback<T>(): ((data: Response<T>) => void) | undefined {
    return this.callback;
  }
  getErrorFun(): ((error: object) => void) | undefined {
    return this.errorFun;
  }
}

class CallPut extends Call {
  private method: callType = 'put';
  private addr: string | undefined;
  private callData = {};
  private callback: ((data: Response<any>) => void) | undefined;
  private errorFun: ((error: object) => void) | undefined;
  call<T>(addr: string, callData: object, callback?: (data: Response<T>) => void, errorFun?: (error: object) => void): void {
    this.getMethod();
    this.addr = addr;
    this.callData = callData;
    this.callback = callback;
    this.errorFun = errorFun;
    this.request();
  }
  getMethod(): callType {
    return this.method;
  }
  getAddr(): string {
    if (!this.addr) {
      throw new Error('호출 주소가 정의 되어있지 않습니다.');
    }
    return this.addr;
  }
  getData(): object {
    return this.callData;
  }
  getCallback<T>(): ((data: Response<T>) => void) | undefined {
    return this.callback;
  }
  getErrorFun(): ((error: object) => void) | undefined {
    return this.errorFun;
  }
}

class CallDelete extends Call {
  private method: callType = 'delete';
  private addr: string | undefined;
  private callData = {};
  private callback: ((data: Response<any>) => void) | undefined;
  private errorFun: ((error: object) => void) | undefined;
  call<T>(addr: string, callData: object, callback?: (data: Response<T>) => void, errorFun?: (error: object) => void): void {
    addr += createQueryString(callData);
    this.getMethod();
    this.addr = addr;
    this.callData = callData;
    this.callback = callback;
    this.errorFun = errorFun;
    this.request();
  }
  getMethod(): callType {
    return this.method;
  }
  getAddr(): string {
    if (!this.addr) {
      throw new Error('호출 주소가 정의 되어있지 않습니다.');
    }
    return this.addr;
  }
  getData(): object {
    return this.callData;
  }
  getCallback<T>(): ((data: Response<T>) => void) | undefined {
    return this.callback;
  }
  getErrorFun(): ((error: object) => void) | undefined {
    return this.errorFun;
  }
}

// export const callGetList = new CallGetList();
export const callGet = new CallGet();
export const callPost = new CallPost();
export const callPut = new CallPut();
export const callDelete = new CallDelete();

export const callMapping = { callGet, callPost, callPut, callDelete };

export type callMappingType = keyof typeof callMapping;
