// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

// import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure your project is capable of handling css files
// import { createVuetify } from 'vuetify';
// import { aliases, md } from 'vuetify/iconsets/md';

// export default createVuetify({
//   icons: {
//     defaultSet: 'md',
//     aliases,
//     sets: {
//       md,
//     },
//   },
// });
